import Cookie from './cookie'
/**
 * 储存
 */
class Storage {

    constructor (driver = 'local'){
        this.driver = driver
    }

    /**
     * @param key
     * @param value
     * @returns {string|any | string}
     */
    get(key , value = {}) {
        let item = this.instance().getItem(key)
        if(!item){
            return value
        }

        try{
            item = JSON.parse(item)
        }catch (e) {
            return value
        }

        return item
    }

    /**
     * @param key
     * @param value
     * @param expiredIn
     */
    set(key, value, expiredIn = 7200){
        if(typeof value == "object"){
            value = JSON.stringify(value)
        }
        if(this.driver === 'cookie'){
            this.instance().setItem(key, value, expiredIn)
        }
        this.instance().setItem(key, value)
    }

    /**
     * @param key
     * @returns {*}
     */
    delete(key){
        return this.instance().removeItem(key)
    }

    /**
     * @returns {Storage}
     */
    instance() {
        switch (this.driver) {
            case 'session':
                return sessionStorage
            case 'cookie':
                return new Cookie
            default:
                return localStorage
        }
    }

}

export default Storage
