class Cookie {
    /**
     * @returns {string|null}
     */
    getItem(){
        let arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
        arr = document.cookie.match(reg)
        if(arr){
            return unescape(arr[2]);
        }else{
            return null;
        }
    }

    /**
     * @param name
     * @param value
     * @param time
     */
    setItem(name, value, time){
        let exp = new Date();
        exp.setTime(exp.getTime() + time);
        document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
    }

    /**
     *
     * @param name
     */
    removeItem(name){
        let exp = new Date();
        exp.setTime(exp.getTime() - 1);
        document.cookie= name + "=;expires="+exp.toGMTString()
    }
}

export default Cookie
